<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText" @click="Get_chiTietLenhDaCap(item.IdLenhDienTu)">
                    <div class="mr-2">
                        <i class="mdi mdi-script-text-outline"></i>
                    </div>
                    <div :class="textRight">
                        <span class="text-link">
                            {{ item.MaSoLenh ? item.MaSoLenh : "" }}
                        </span>
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.GioXuatBen ? item.GioXuatBen.substr(0, 5) : "" }}
                        {{ moment(item.NgayXuatBen).format("DD/MM/yyyy") }}
                    </div>
                    <span> - </span>
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.BienSoXe ? item.BienSoXe : "" }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-road-variant"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            `${item.BenDi_TenBenXe} - ${item.BenDen_TenBenXe} (${item.MaTuyen})`
                        }}
                    </div>
                </div>

                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-steering"></i>
                    </div>
                    <div :class="textRight" v-if="item.DanhSachLaiXe">
                        <span
                            :class="`${classText} ${index == 0 ? ' color-primary' : ''}`"
                            v-for="(laiXe, index) in sortLaiXe(item.DanhSachLaiXe)"
                            :key="index"
                        >
                            {{ index == 0 ? laiXe.HoTen : " - " + laiXe.HoTen }}</span
                        >
                    </div>
                </div>
                <div :class="rowText" v-if="item.HoTenPhuXe">
                    <div class="mr-2">
                        <i class="mdi mdi-account-tie-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.HoTenPhuXe }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div :class="textRight">
                        <span :style="{ color: item.MaMauTrangThaiKyLenh }">
                            {{
                                item.TenTrangThaiKyLenh
                                    ? item.TenTrangThaiKyLenh
                                    : "(Trống)"
                            }}</span
                        >
                        <span> - </span>
                        <span :style="{ color: item.MaMauTrangThaiLenh }">
                            {{
                                item.TenTrangThaiLenh ? item.TenTrangThaiLenh : "(Trống)"
                            }}</span
                        >
                    </div>
                </div>
                <div :class="rowLastText">
                    <div class="mr-2">
                        <i class="mdi mdi-ticket-confirmation-outline"></i>
                    </div>
                    <div :class="textRight">
                        <span :style="{ color: item.MaMauTrangThaiChuyenDiVDT }">
                            {{
                                item.TrangThaiChuyenDiVDT
                                    ? item.TrangThaiChuyenDiVDT
                                    : "(Trống)"
                            }}</span
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            text="Chức năng khác"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="showPopup('PopupDanhSachChucNang')"
                            class="color-danger"
                        />
                        <!-- <DxButton
                            text="Hủy lệnh"
                            type="danger"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.HuyLenh'),
                                )
                            "
                            @click="huyLenh(item)"
                            class="color-danger"
                        /> -->
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe',
                                    )
                                ] == 'true' &&
                                $Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.CanBoKiemTraXe'),
                                ) &&
                                !item.DaXacNhanKiemTraXe
                            "
                            text="Đã kiểm tra xe"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            class="color-primary"
                            @click="QuanLyXacNhanLenh(item)"
                        />
                        <DxButton
                            v-else
                            text="Ký lại lệnh"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            class="color-primary"
                            @click="showKyLaiLenh(item)"
                            :disabled="
                                item.TrangThaiKyLenh ||
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLaiLenh'),
                                )
                            "
                        />
                    </div>
                    <!-- :disabled='item.MaMauTrangThaiChuyenDiVDT == "#13B000" ? true : false' -->
                </div>
            </div>
        </div>
        <!-- v-if="!item.TrangThaiKyLenh" -->
        <div class="row" v-if="!item.MaMauTrangThaiChuyenDiVDT == '#13B000'">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div class="xs12 text-xs-center">
                        <DxButton
                            text="Tạo chuyến đi bán vé"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="TaoChuyenDiBanVe(item)"
                            class="color-primary"
                            :disabled="
                                item.MaMauTrangThaiChuyenDiVDT == '#13B000' ? true : false
                            "
                        />
                    </div>
                    <!-- :disabled='item.MaMauTrangThaiChuyenDiVDT == "#13B000" ? true : false' -->
                </div>
            </div>
        </div>
    </div>

    <DxActionSheet
        v-if="ifPopupDanhSachChucNang"
        v-model:visible="PopupDanhSachChucNang"
        ref="PopupDanhSachChucNang"
        title="Chọn chức năng"
        :elementAttr="{
            class: 'custom-action-sheet',
        }"
        :showCancelButton="false"
        :data-source="[
            {
                text: 'Hủy lệnh',
                template: 'customItem',
            },
        ]"
    >
        <template #customItem="{}">
            <div class="row">
                <div class="xs12">
                    <DxButton
                        v-if="
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.BenDiDaKy')) &&
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.ChoXacNhanTraKhach')) &&
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.HoanThanh')) &&
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.KhongThucHienHanhTrinh')) &&
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.KhongHoanThanhHanhTrinh')) &&
                            item.IdTrangThaiLenh != parseInt($t('TrangThaiLenh.Huy')) &&
                            item.IdTrangThaiLenh !=
                                parseInt($t('TrangThaiLenh.LaiXeKhongTiepNhan')) &&
                            $Helper.KiemTraQuyen(
                                $t('QuyenLenhDienTu.LENHDIENTU'),
                                $t('QuyenLenhDienTu.ChinhSuaLenh'),
                            )
                        "
                        type="default"
                        styling-mode="outlined"
                        text="Chỉnh sửa lệnh"
                        @click="ChinhSuaLenh()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="xs12">
                    <DxButton
                        v-if="
                            ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                $t('MaCauHinhDoanhNghiep.SuDungQuyTrinhCanBoKiemTraXe')
                            ] == 'true' &&
                            $Helper.KiemTraQuyen(
                                $t('QuyenLenhDienTu.LENHDIENTU'),
                                $t('QuyenLenhDienTu.CanBoKiemTraXe'),
                            ) &&
                            !item.DaXacNhanKiemTraXe
                        "
                        text="Đã kiểm tra xe"
                        type="default"
                        styling-mode="outlined"
                        class="mt-3"
                        @click="QuanLyXacNhanLenh(item)"
                    />
                    <DxButton
                        v-else-if="
                            !(
                                item.TrangThaiKyLenh ||
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLaiLenh'),
                                )
                            )
                        "
                        text="Ký lại lệnh"
                        type="default"
                        styling-mode="outlined"
                        width="100%"
                        class="mt-3"
                        @click="showKyLaiLenh(item)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="xs12">
                    <DxButton
                        text="Hủy lệnh"
                        type="danger"
                        styling-mode="outlined"
                        :disabled="
                            !$Helper.KiemTraQuyen(
                                $t('QuyenLenhDienTu.LENHDIENTU'),
                                $t('QuyenLenhDienTu.HuyLenh'),
                            )
                        "
                        @click="huyLenh(item)"
                        class="mt-3"
                    />
                </div>
            </div>
            <div class="row mt-3">
                <div class="xs12">
                    <DxButton text="Đóng" type="danger" styling-mode="contained" />
                </div>
            </div>
        </template>
    </DxActionSheet>
</template>

<script>
import { DxButton, DxActionSheet } from "devextreme-vue";
export default {
    components: {
        DxButton,
        DxActionSheet,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
            ifPopupDanhSachChucNang: false,
            PopupDanhSachChucNang: false,
        };
    },
    computed: {
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    watch: {
        PopupDanhSachChucNang() {
            if (!this.PopupDanhSachChucNang) {
                this.NgatDOMPopup("ifPopupDanhSachChucNang");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 200);
        },
        huyLenh(item) {
            this.$store.commit("DVVTLenh/SET_DIALOG_LENH", true);
            this.$store.commit("DVVTLenh/SET_IDLENH", item.IdLenhDienTu);
        },
        NhanLenh(item) {
            this.$emit("NhanLenh", item);
        },
        XemBanTheHienLenh(item) {
            this.$emit("XemBanTheHienLenh", item);
        },
        async Get_chiTietLenhDaCap(id) {
            try {
                let rs = await this.$store.dispatch("DVVTLenh/Get_ChiTietLenh", {
                    IdLenhDienTu: id,
                });
                if (rs.Data.errorCode == 0) {
                    this.$store.commit("DVVTLenh/SET_GUID_LENH", rs.Data.data.GuidLenh);
                    this.$store.commit("DVVTLenh/SET_DIALOG_THEHIEN", true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        TaoChuyenDiBanVe(item) {
            this.$store.commit(
                "DVVTLenh/SET_ID_CHUYEN_DI_BAN_VE_DANG_CHON",
                item.IdLenhDienTu,
            );
            this.$store.commit("DVVTLenh/SET_DIALOG_BAO_MAT", true);
        },
        showKyLaiLenh(item) {
            this.$store.commit("DVVTLenh/SET_DIALOG_KY_LAI_LENH", true);
            this.$store.commit("DVVTLenh/SET_IDLENH", item.IdLenhDienTu);
        },
        QuanLyXacNhanLenh(item) {
            this.$store.commit("DVVTLenh/SET_IDLENH", item.IdLenhDienTu);
            this.emitter.emit("DNVTLenh_ShowPopup", {
                name: "PopupQuanLyXacNhanLenh",
            });
        },
        sortLaiXe(arr) {
            let tmpArr = [];
            arr.forEach((e) => {
                if (e.TiepNhanLenh) {
                    tmpArr.unshift(e);
                } else {
                    tmpArr.push(e);
                }
            });
            return tmpArr;
        },
        ChinhSuaLenh() {
            this.$router.push("/DNVT-Quan-Ly-Lenh/Chinh-Sua-Lenh");
            this.$store.commit("DVVTLenh/Set", {
                key: "ThongTinChinhSuaLenh",
                data: JSON.parse(JSON.stringify(this.item)),
            });
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
:deep(.custom-action-sheet .dx-toolbar-label div) {
    font-size: 20px;
    font-weight: 500;
}
</style>
