<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.Not ? item.Not.substr(0, 5) : "" }}
                        {{ moment(item.NgayDuong).format("DD/MM/yyyy") }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.BienSoXe ? item.BienSoXe : "(Trống)" }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-road-variant"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            `${item.TenBenXeDi} - ${item.TenBenXeDen} (${item.MaTuyen})` ||
                            "(Trống)"
                        }}
                    </div>
                </div>

                <div :class="rowLastText">
                    <div class="mr-2">
                        <i class="mdi mdi-steering"></i>
                    </div>

                    <div :class="textRight" v-if="item.DanhSachLaiXeThucHien">
                        <span
                            :class="`${classText} ${index == 0 ? ' color-primary' : ''}`"
                            v-for="(laiXe, index) in item.DanhSachLaiXeThucHien"
                            :key="index"
                        >
                            {{ index == 0 ? laiXe.HoTen : " - " + laiXe.HoTen }}</span
                        >
                    </div>
                    <div :class="textRight" v-else>
                        <span>{{ "(Trống)" }}</span>
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-account-tie-outline"></i>
                    </div>
                    <div :class="textRight" :style="`color: ${item.maMauTrangThai}`">
                        {{ item.HoTenPhuXe ? item.HoTenPhuXe : "(Trống)" }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            text="Sửa kế hoạch"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.CapNhatKeHoach'),
                                )
                            "
                            @click="SuaKeHoach(item)"
                            class="color-danger"
                            style="color: #fb8c00"
                        />
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            text="Ký lệnh"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.KyLenh'),
                                )
                            "
                            @click="KyLenh(item)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    methods: {
        KyLenh(item) {
            let dsLaiXe = item.DanhSachLaiXeThucHien ? item.DanhSachLaiXeThucHien : [];
            let dsBienKS = item.XeThucHien ? item.XeThucHien : {};
            let HoTenPhuXe = item.HoTenPhuXe;
            this.$store.commit("DVVTLenh/Set", {
                key: "NgayDuong",
                data: item.NgayDuong,
            });
            this.$store.commit("DVVTLenh/Set", {
                key: "TenTuyen",
                data: item.TenTuyen,
            });
            this.$store.commit("DVVTLenh/SET_LAI_XE_TIEP_NHAN_LENH", dsLaiXe);
            this.$store.commit("DVVTLenh/SET_BIEN_KIEM_SOAT", dsBienKS);
            this.$store.commit("DVVTLenh/SET_HO_TEN_PHU_XE", HoTenPhuXe);
            this.$router.push({
                path: "/DNVT-Quan-Ly-Lenh/Ky-Lenh",
                query: { IdKeHoach: item.ID_KeHoach },
            });
        },
        SuaKeHoach(item) {
            let dsLaiXe = item.DanhSachLaiXeThucHien ? item.DanhSachLaiXeThucHien : [];
            let dsBienKS = item.XeThucHien ? item.XeThucHien : {};
            let HoTenPhuXe = item.HoTenPhuXe;
            this.$store.commit("DVVTLenh/Set", {
                key: "TenTuyen",
                data: item.TenTuyen,
            });
            this.$store.commit("DVVTLenh/SET_LAI_XE_TIEP_NHAN_LENH", dsLaiXe);
            this.$store.commit("DVVTLenh/SET_BIEN_KIEM_SOAT", dsBienKS);
            this.$store.commit("DVVTLenh/SET_HO_TEN_PHU_XE", HoTenPhuXe);
            this.$router.push({
                path: "/DNVT-Quan-Ly-Lenh/Sua-Ke-Hoach",
                query: { IdKeHoach: item.ID_KeHoach },
            });
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
