<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText" @click="Get_chiTietLenhDaCap(item.IdLenhDienTu)">
                    <div class="mr-2">
                        <i class="mdi mdi-script-text-outline"></i>
                    </div>
                    <div :class="textRight">
                        <span class="text-link">
                            {{ item.MaSoLenh ? item.MaSoLenh : "" }}
                        </span>
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.GioXuatBen ? item.GioXuatBen.substr(0, 5) : "" }}
                        {{ moment(item.NgayXuatBen).format("DD/MM/yyyy") }}
                    </div>
                    <span> - </span>
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.BienSoXe ? item.BienSoXe : "" }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-road-variant"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            `${item.BenDi_TenBenXe} - ${item.BenDen_TenBenXe} (${item.MaTuyen})`
                        }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-steering"></i>
                    </div>
                    <div :class="textRight" v-if="item.DanhSachLaiXe">
                        <span
                            :class="`${classText} ${index == 0 ? ' color-primary' : ''}`"
                            v-for="(laiXe, index) in sortLaiXe(item.DanhSachLaiXe)"
                            :key="index"
                        >
                            {{ index == 0 ? laiXe.HoTen : " - " + laiXe.HoTen }}</span
                        >
                    </div>
                </div>
                <div :class="rowText" v-if="item.HoTenPhuXe">
                    <div class="mr-2">
                        <i class="mdi mdi-account-tie-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.HoTenPhuXe }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div :class="textRight">
                        <span :style="{ color: item.MaMauTrangThaiLenh }">
                            {{ item.TenTrangThaiLenh ? item.TenTrangThaiLenh : "" }}</span
                        >
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div
                        :class="[
                            textRight,
                            !!item.TruyenTaiXuatBenThanhCong
                                ? ' color-success'
                                : ' color-error',
                        ]"
                    >
                        <span>
                            {{
                                !!item.TruyenTaiXuatBenThanhCong
                                    ? "Truyền tải xuất bến thành công"
                                    : "Truyền tải xuất bến thất bại"
                            }}</span
                        >
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div
                        :class="[
                            textRight,
                            !!item.TruyenTaiDenBenThanhCong
                                ? ' color-success'
                                : ' color-error',
                        ]"
                    >
                        <span>
                            {{
                                !!item.TruyenTaiDenBenThanhCong
                                    ? "Truyền tải đến bến thành công"
                                    : "Truyền tải đến bến thất bại"
                            }}</span
                        >
                    </div>
                </div>
                <div :class="rowLastText">
                    <div class="mr-2">
                        <i class="mdi mdi-ticket-confirmation-outline"></i>
                    </div>
                    <div :class="textRight">
                        <span :style="{ color: item.MaMauTrangThaiChuyenDiVDT }">
                            {{
                                item.TrangThaiChuyenDiVDT ? item.TrangThaiChuyenDiVDT : ""
                            }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    methods: {
        huyLenh(item) {
            this.$emit("huyLenh", item);
        },
        NhanLenh(item) {
            this.$emit("NhanLenh", item);
        },
        XemBanTheHienLenh(item) {
            this.$emit("XemBanTheHienLenh", item);
        },
        async Get_chiTietLenhDaCap(id) {
            try {
                let rs = await this.$store.dispatch("DVVTLenh/Get_ChiTietLenh", {
                    IdLenhDienTu: id,
                });
                if (rs.Data.errorCode == 0) {
                    this.$store.commit("DVVTLenh/SET_GUID_LENH", rs.Data.data.GuidLenh);
                    this.$store.commit("DVVTLenh/SET_DIALOG_THEHIEN", true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        sortLaiXe(arr) {
            let tmpArr = [];
            arr.forEach((e) => {
                if (e.TiepNhanLenh) {
                    tmpArr.unshift(e);
                } else {
                    tmpArr.push(e);
                }
            });
            return tmpArr;
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
