<template>
    <div class="frame-card">
        <div
            class="item-card"
            v-for="(item, index) in DanhSachTieuChi"
            :key="index"
            @click="SapXep(item, index)"
            :class="item.order != 0 ? 'active' : ''"
        >
            <i
                class="mdi mdi-swap-vertical mr-1"
                style="font-size: 16px"
                v-if="item.order == 0"
            ></i>
            <i
                class="mdi mdi-sort-bool-ascending mr-1"
                style="font-size: 16px"
                v-if="item.order == 1"
            ></i>
            <i
                class="mdi mdi-sort-bool-descending mr-1"
                style="font-size: 16px"
                v-if="item.order == 2"
            ></i>
            {{ item.text }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        DanhSachTieuChi: {
            get() {
                return this.$store.state.DVVTLenh.DanhSachTieuChi;
            },
            set(data) {
                this.$store.commit("DVVTLenh/Set", {
                    key: "DanhSachTieuChi",
                    data: data,
                });
            },
        },
    },
    methods: {
        SapXep(item, index) {
            this.DanhSachTieuChi.forEach((e, i) => {
                if (index != i) {
                    e.order = 0;
                    e.kieuSapXep = "";
                }
            });

            if (this.DanhSachTieuChi[index].order < 2) {
                this.DanhSachTieuChi[index].order++;
            } else {
                this.DanhSachTieuChi[index].order = 0;
            }
            let order = this.DanhSachTieuChi[index].order;
            this.DanhSachTieuChi[index].kieuSapXep =
                order == 0 ? "" : order == 1 ? "ASC" : order == 2 ? "DESC" : "";

            this.$emit("sapXepTuyen", item);
        },
    },
};
</script>

<style scoped>
.frame-card {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    margin: 0 8px;
}
.frame-card::-webkit-scrollbar {
    display: none;
}
.item-card {
    border: 1px solid #dadce0;
    margin-right: 8px;
    border-radius: 24px;
    padding: 4px 12px 4px 8px;
    width: auto;
    height: 36px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    box-shadow: inset 0 0 5px #dadce0;
}
.item-card.active {
    border: 1px solid #03a9f4;

    box-shadow: inset 0 0 5px #03a9f4;
    color: #03a9f4;
}
</style>